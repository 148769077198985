import React from 'react'
import { FooterLink, FooterLinks, FooterLogo, SocialLink, SocialLinks, VectaCopyrights, VectaFooter } from './footer.components';

import VectaLogo from '../../assets/images/vecta-logo.svg';
import WhatsAppIcon from '../../assets/icons/whatsapp.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import YouTubeIcon from '../../assets/icons/youtube.svg';

const Footer = () => {
    return (
        <>
            <VectaFooter>
                <FooterLogo href='/' aria-label='Vecta Photography'>
                    <img src={VectaLogo} alt="Vecta Photography" />
                </FooterLogo>
                <FooterLinks>
                    <FooterLink href='/'>Home</FooterLink>
                    <FooterLink href='#about'>About Us</FooterLink>
                    <FooterLink href='#portfolio'>Portfolio</FooterLink>
                    <FooterLink href='#pricing'>Pricing</FooterLink>
                    <FooterLink href='#faq'>FAQs</FooterLink>
                    <FooterLink href='#contact'>Contact Us</FooterLink>
                </FooterLinks>
                <SocialLinks>
                    <SocialLink href='https://wa.me/918121916030' target='_blank' aria-label='vecta-social-link'>
                        <img src={WhatsAppIcon} alt="WhatsApp" />
                    </SocialLink>
                    <SocialLink href='https://www.instagram.com/vectaphotography/' target='_blank' aria-label='vecta-social-link'>
                        <img src={InstagramIcon} alt="Instagram" />
                    </SocialLink>
                    <SocialLink href='https://www.facebook.com/vectaphotography' target='_blank' aria-label='vecta-social-link'>
                        <img src={FacebookIcon} alt="Facebook" />
                    </SocialLink>
                    {/* <SocialLink href='javascript:void(0)' aria-label='vecta-social-link'>
                        <img src={YouTubeIcon} alt="YouTube" />
                    </SocialLink> */}
                </SocialLinks>
            </VectaFooter>
            <VectaCopyrights>
                <span>©<span className="year">{(new Date().getFullYear())}</span> - Vecta | All rights reserved</span>
            </VectaCopyrights>
        </>
    )
}

export default Footer;
