import styled from "styled-components";
import { VectaOrange, VectaBlackV2 } from "../../components/colors";

export const AboutContentWrapper = styled.div`
display: flex;
align-items: center;
gap: 100px;
@media screen and (max-width: 991px){
    flex-direction: column;
    gap: 60px;
}
`

export const AboutContent = styled.div`
    flex: 1;
`

export const AboutImageSec = styled.div`
    width: 50%;
    height: 650px;
    padding: 12px 0 0 12px;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 80%;
        height: 80%;
        background-color: transparent;
        border: 4px solid ${VectaOrange};
        border-radius: 250px 0 0 60px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @media screen and (max-width: 575px){
            border-radius: 60px;
        }
    }
    &::after{
        content: '';
        width: calc(100% + 32px + 100px);
        height: 50%;
        background-color: rgba(11, 12, 12, 0.9);
        border-radius: 40px 0 0 40px;
        position: absolute;
        top: 50%;
        right: calc(-100px);
        transform: translateY(-50%);
        z-index: -2;
        @media screen and (max-width: 991px){
            display: none;
        }
    }
    @media screen and (max-width: 991px){
        width: 100%;
        height: 400px;
        margin-right: -60px;
    }
    @media screen and (max-width: 575px){
        margin: 0;
    }
`

export const AboutImageContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${VectaBlackV2};
    border-radius: 250px 0 0 60px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    @media screen and (max-width: 575px){
        border-radius: 60px;
    }
`