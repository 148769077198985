import React from 'react'
import { ContactIconWrapper, VectaContactDetails, VectaContactForm, VectaContactFormWrapper, VectaContactInfo, VectaContactSec, VectaContactWrapper, VectaFormField, VectaFormFieldWrapper, VectaFormInput, VectaFormTextArea, VectaSubmitBtn } from './contact.components';

import phoneIcon from '../../assets/icons/phone.svg';
import addressBookIcon from '../../assets/icons/address-book.svg';
import emailIcon from '../../assets/icons/email.svg';

const Contact = () => {

    function handleSubmit(e: any) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        var data = {
            service_id: 'service_d521jpc',
            template_id: 'template_eb4iwfx',
            user_id: 'Rj6cIkoYA1U06veEy',
            template_params: formProps
        }

        fetch("https://api.emailjs.com/api/v1.0/email/send", {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((resp) => {
                if (resp) {
                    window.location.reload();
                }
            })
            .then((data) => console.log(data));
    }
    return (
        <>
            <VectaContactSec id='contact'>
                <div className="container">
                    <VectaContactWrapper>
                        <VectaContactFormWrapper>
                            <div className="section-title-wrapper">
                                <h1 className="section-caption">Get in Touch</h1>
                                <h1 className="section-title">Contact Us</h1>
                            </div>
                            <VectaContactForm name='contactform' onSubmit={handleSubmit}>
                                <VectaFormField>
                                    <VectaFormInput type="text" placeholder='Your Name*' name="to_name" className="form-control" required />
                                </VectaFormField>
                                <VectaFormFieldWrapper>
                                    <VectaFormField>
                                        <VectaFormInput type="email" placeholder='Email Address*' name="from_email" className="form-control" required />
                                    </VectaFormField>
                                    <VectaFormField>
                                        <VectaFormInput type="tel" placeholder='Phone Number*' name="from_phone" className="form-control" required />
                                    </VectaFormField>
                                </VectaFormFieldWrapper>
                                <VectaFormField>
                                    <VectaFormTextArea rows={4} placeholder='Your Message*' name="message" className="form-control" required></VectaFormTextArea>
                                </VectaFormField>
                                <VectaSubmitBtn type='submit'>Submit</VectaSubmitBtn>
                            </VectaContactForm>
                        </VectaContactFormWrapper>
                        <VectaContactDetails>
                            <VectaContactInfo>
                                <ContactIconWrapper>
                                    <img src={phoneIcon} alt="Contact Number" />
                                </ContactIconWrapper>
                                <h4>Contact Number</h4>
                                <span>+91 812 191 6030</span>
                                <span>+91 996 660 2345</span>
                            </VectaContactInfo>
                            <VectaContactInfo>
                                <ContactIconWrapper>
                                    <img src={addressBookIcon} alt="Address" />
                                </ContactIconWrapper>
                                <h4>Our Address</h4>
                                <span>Jubilee hills, Hyderabad</span>
                            </VectaContactInfo>
                            <VectaContactInfo>
                                <ContactIconWrapper>
                                    <img src={emailIcon} alt="Email" />
                                </ContactIconWrapper>
                                <h4>Email Address</h4>
                                <span>vectaphotography@gmail.com</span>
                            </VectaContactInfo>
                        </VectaContactDetails>
                    </VectaContactWrapper>
                </div>
            </VectaContactSec>

        </>
    )
}

export default Contact;
