import React, { useState } from 'react'

import { VectaNavigation, VectaNavbar, VectaNavLogoWrapper, VectaNavLinks, VectaNavLink, VectaNavHamburger } from './header.components';

import VectaLogo from '../../assets/images/vecta-logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isClicked, setIsClicked] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    function openMenu() {
        setIsClicked(!isClicked);
        setIsOpen(!isOpen);
    }

    return (
        <>
            <VectaNavigation>
                <div className="container">
                    <VectaNavbar>
                        <VectaNavLogoWrapper href='/' aria-label='Vecta Photography'>
                            <img src={VectaLogo} alt="Vecta Photography" />
                        </VectaNavLogoWrapper>
                        <VectaNavLinks className={(isOpen ? 'open' : '')}>
                            <VectaNavLink href="/">Home</VectaNavLink>
                            <VectaNavLink href="/#about">About Us</VectaNavLink>
                            <VectaNavLink href="#portfolio">Portfolio</VectaNavLink>
                            <VectaNavLink href="#pricing">Pricing</VectaNavLink>
                            <VectaNavLink href="#faq">FAQs</VectaNavLink>
                            <VectaNavLink href="#contact">Contact Us</VectaNavLink>
                        </VectaNavLinks>
                        <VectaNavHamburger onClick={() => openMenu()} className={(isClicked ? 'clicked' : '')}>
                            <span className='top'></span>
                            <span className='middle'></span>
                            <span className='bottom'></span>
                        </VectaNavHamburger>
                    </VectaNavbar>
                </div>
            </VectaNavigation>
        </>
    );

}

export default Header
