import styled from "styled-components";

import ContactBGImage from '../../assets/images/image-3.png'
import { VectaBlackV2, VectaBlackV3, VectaGrayV2, VectaHeadingColor, VectaOrange, VectaTextColor, VectaWhite } from "../../components/colors";

export const VectaContactSec = styled.div`
    padding: 120px 0 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${ContactBGImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    overflow: hidden;
    @media screen and (max-width: 991px){
        padding: 60px 0;
    }
`

export const VectaContactWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 120px;
    @media screen and (max-width: 991px){
        flex-direction: column;
        gap: 40px;
    }
    @media screen and (min-width: 1367px){
        max-width: 80%;
        margin: 0 auto;
    }
`

export const VectaContactFormWrapper = styled.div`
    flex: 1;
    margin: 80px 0;
    @media screen and (max-width: 991px){
        width: 100%;
        margin: 0;
    }
`

export const VectaContactForm = styled.form`
    .vecta-btn{
        margin-top: 32px;
    }
`

export const VectaFormField = styled.div`
    display: flex;
    flex-direction: column;
    &:not(:last-of-type){
        margin-bottom: 16px;
    }
`

export const VectaFormFieldWrapper = styled.div`
    display: flex;
    gap: 16px;
    &>div{
        flex: 1;
    }
    @media screen and (max-width: 575px){
        flex-direction: column;
        gap: 0;
        margin-bottom: 16px;
    }
`

export const VectaFormInput = styled.input`
    padding: 12px 24px;
    background: ${VectaGrayV2};
    border: 1px solid transparent;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${VectaWhite};
    outline: none !important; 
    box-shadow: none !important;
    ::placeholder{
        color: ${VectaTextColor};
    }
    &:focus{
        border-color: ${VectaOrange};
        background-color: ${VectaBlackV3};
    }
`

export const VectaFormTextArea = styled.textarea`
    padding: 12px 24px;
    background: ${VectaGrayV2};
    border: 1px solid transparent;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${VectaWhite};
    outline: none !important; 
    box-shadow: none !important;
    resize: none;
    ::placeholder{
        color: ${VectaTextColor};
    }
    &:focus{
        border-color: ${VectaOrange};
        background-color: ${VectaBlackV3};
    }
`

export const VectaSubmitBtn = styled.button`
    background-color: ${VectaOrange};
    border-radius: 30px;
    padding: 15px 30px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #FFF0E9 !important;
    text-align: center;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 40px;
    cursor: pointer;
`

export const VectaContactDetails = styled.div`
width: 35%;
align-self: stretch;
background-color: ${VectaGrayV2};
border-radius: 250px 250px 0 0;
padding: 40px;
display: flex;
flex-direction: column;
justify-content: center;
gap: 48px;
position: relative;
z-index: 1;
&::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 3px solid ${VectaOrange};
    border-bottom: 0;
    border-radius: 250px 250px 0 0;
    position: absolute;
    top: 0;
    left: -24px;
    z-index: -1;
    @media screen and (max-width: 991px){
        display: none;
    }
}
@media screen and (max-width: 991px){
    width: 100%;
    border-radius: 60px;
}
`

export const VectaContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: #A1AEB7;
    text-align: center;
    h4{
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        color: ${VectaHeadingColor};
        margin: 12px 0;
    }
    span{
        display: block;
        &:not(:last-of-type){
            margin-bottom: 8px;
        }
    }
`

export const ContactIconWrapper = styled.div`
    width: 70px;
    height: 70px;
    background-color: ${VectaBlackV2};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 100%;
    }
`