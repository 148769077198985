import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './style.css'
import VectaWebsiteDesign from './views/vecta-website-design'
import VectaWebsiteGallery from './views/vecta-website-gallery'
// import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/global-styles.scss'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            element={<VectaWebsiteDesign />}
            path="/"
          />
          <Route
            element={<VectaWebsiteGallery />}
            path="/portfolio"
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
