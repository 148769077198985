import React from 'react'
import { Link } from 'react-router-dom';
import { ButtonContainer, VectaGalleryColumn, VectaGalleryRow } from './gallery.components';

const Gallery = () => {
    return (
        <div className='vecta-section bg-gray' id='portfolio'>
            <div className="container">

                <div className="section-title-wrapper text-center">
                    <h1 className="section-caption">Our</h1>
                    <h1 className="section-title">Professional Portfolio</h1>
                </div>
                <VectaGalleryRow>
                    <VectaGalleryColumn>
                        <img
                            src="/gallery/gallery_sm_1.jpg"
                            alt="gallery_sm_01"
                        />
                        <img
                            src="/gallery/gallery_sm_2.jpg"
                            alt="gallery_sm_02"
                        />
                        <img
                            src="/gallery/gallery_sm_3.jpg"
                            alt="gallery_sm_03"
                        />
                        <img
                            src="/gallery/gallery_sm_4.jpg"
                            alt="gallery_sm_04"
                        />
                    </VectaGalleryColumn>
                    <VectaGalleryColumn>
                        <img
                            src="/gallery/gallery_sm_5.jpg"
                            alt="gallery_sm_05"
                        />
                        <img
                            src="/gallery/gallery_sm_6.jpg"
                            alt="gallery_sm_06"
                        />
                        <img
                            src="/gallery/gallery_sm_7.jpg"
                            alt="gallery_sm_07"
                        />
                        <img
                            src="/gallery/gallery_sm_8.jpg"
                            alt="gallery_sm_08"
                        />
                    </VectaGalleryColumn>
                    <VectaGalleryColumn>
                        <img
                            src="/gallery/gallery_sm_9.jpg"
                            alt="gallery_sm_09"
                        />
                        <img
                            src="/gallery/gallery_sm_10.jpg"
                            alt="gallery_sm_10"
                        />
                        <img
                            src="/gallery/gallery_sm_11.jpg"
                            alt="gallery_sm_12"
                        />
                        <img
                            src="/gallery/gallery_sm_13.jpg"
                            alt="gallery_sm_13"
                        />
                    </VectaGalleryColumn>
                    <VectaGalleryColumn>
                        <img
                            src="/gallery/gallery_sm_14.jpg"
                            alt="gallery_sm_14"
                        />
                        <img
                            src="/gallery/gallery_sm_15.jpg"
                            alt="gallery_sm_15"
                        />
                        <img
                            src="/gallery/gallery_sm_16.jpg"
                            alt="gallery_sm_16"
                        />
                        <img
                            src="/gallery/gallery_sm_17.jpg"
                            alt="gallery_sm_17"
                        />
                    </VectaGalleryColumn>
                </VectaGalleryRow>

                <ButtonContainer>
                    <Link to="/portfolio" className='vecta-btn'>Load More</Link>
                </ButtonContainer>
            </div>
        </div>
    )
}
export default Gallery;