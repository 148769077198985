import React from 'react'
import { Price, PricingCard, PricingCardsWrapper, PricingCardTitle, PricingCardTitleCaption, PricingFeatureList, PricingFeatures } from './pricing.components';

const Pricing = () => {
    return (
        <div className="vecta-section bg-gray" id="pricing">
            <div className="container">
                <div className="section-title-wrapper text-center">
                    <h1 className="section-caption">Our</h1>
                    <h1 className="section-title">Pricing</h1>
                </div>
                <PricingCardsWrapper>
                    <PricingCard>
                        <PricingCardTitle>Silver</PricingCardTitle>
                        <PricingFeatures>
                            <PricingFeatureList>
                                <li><span>Photography</span></li>
                                <li><span>Make up & Hair Stylist</span></li>
                                <li><span>Planning of Shoot</span></li>
                                <li><span>5 Changes</span></li>
                                <li><span>25 Best Images Selected</span></li>
                                <li><span>Advanced Digital Retouch</span></li>
                                <li><span>Outdoor Shoot</span></li>
                                <li><span>Pictures by Email & WhatsApp</span></li>
                            </PricingFeatureList>
                            <span>(Costumes are not included)</span>
                            <Price>₹20,000</Price>
                        </PricingFeatures>
                        <a href="#contact" className="vecta-btn">Book Now</a>
                    </PricingCard>
                    <PricingCard className='recommended'>
                        <PricingCardTitleCaption>Recommended</PricingCardTitleCaption>
                        <PricingCardTitle>Gold</PricingCardTitle>
                        <PricingFeatures>
                            <PricingFeatureList>
                                <li><span>Photography</span></li>
                                <li><span>Make up & Hair Stylist</span></li>
                                <li><span>Planning of Shoot</span></li>
                                <li><span>5-7 Changes</span></li>
                                <li><span>30 Best Images Selected</span></li>
                                <li><span>Advanced Digital Retouch</span></li>
                                <li><span>Indoor & Outdoor Shoot</span></li>
                                <li><span>Pictures by Email & WhatsApp</span></li>
                            </PricingFeatureList>
                            <span>(Costumes are not included)</span>
                            <Price>₹25,000</Price>
                        </PricingFeatures>
                        <a href="#contact" className="vecta-btn">Book Now</a>
                    </PricingCard>
                    <PricingCard>
                        <PricingCardTitle>Platinum</PricingCardTitle>
                        <PricingFeatures>
                            <PricingFeatureList>
                                <li><span>Photography</span></li>
                                <li><span>Make up & Hair Stylist</span></li>
                                <li><span>Planning of Shoot</span></li>
                                <li><span>7-10 Changes</span></li>
                                <li><span>50 Best Images Selected</span></li>
                                <li><span>Advanced Digital Retouch</span></li>
                                <li><span>Indoor & Outdoor Shoot</span></li>
                                <li><span>Pictures by Email & WhatsApp</span></li>
                                <li><span>Introduction Video</span></li>
                            </PricingFeatureList>
                            <span>(Costumes are not included)</span>
                            <Price>₹35,000</Price>
                        </PricingFeatures>
                        <a href="#contact" className="vecta-btn">Book Now</a>
                    </PricingCard>
                </PricingCardsWrapper>
            </div>
        </div>
    )
}
export default Pricing;