
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from './header/header'
import Footer from './footer/footer'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './vecta-website-design.css';

import vectaFavIcon from '../assets/icons/vecta-favicon.png'
import Contact from './contact/contact';

const VectaWebsiteGallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const imageList: any = new Array(70).fill(0).map((row, index) => `/gallery/gallery_lg_${index + 1}.jpg`);

    function handleClick(index: number) {
        setPhotoIndex(index);
        setIsOpen(true);
    }
    return (
        <>
            <Helmet>
                <link rel="icon" href={vectaFavIcon} />
            </Helmet>
            <Header />
            <div className="vecta-section bg-gray">
                <div className='container'>
                    <div className="section-title-wrapper text-center">
                        <h1 className="section-caption">Our</h1>
                        <h1 className="section-title">Professional Portfolio</h1>
                    </div>
                    <div className="img-container gallery-grid">
                        {new Array(70).fill(0).map((row, index) => {
                            const path = `/gallery/gallery_sm_${index + 1}.jpg`;
                            return <>
                                <figure className="gallery__item" onClick={e => handleClick(index + 1)} key={index}>
                                    <img src={path} alt={path} className="gallery__img"></img>
                                </figure>
                            </>
                        })}
                        {isOpen && (
                            <Lightbox
                                mainSrc={imageList[photoIndex]}
                                nextSrc={imageList[(photoIndex + 1) % imageList.length]}
                                prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + imageList.length - 1) % imageList.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % imageList.length)
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    )
}

export default VectaWebsiteGallery

