import styled from "styled-components";
import { VectaGrayV2 } from "../../components/colors";

export const VectaGalleryRow = styled.div`
    display: flex;
    margin: 0 -6px;
`

export const VectaGalleryColumn = styled.div`
    width: calc(25% - 12px);
    margin: 0 6px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    img{
        /* width: 100%; */
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 80px;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${VectaGrayV2};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: -1;
    }
`