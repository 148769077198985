import React from 'react'
import { AboutContent, AboutContentWrapper, AboutImageSec, AboutImageContainer } from './about.components';

import AboutImage from '../../assets/images/image-2a.jpg';

const AboutUs = () => {
    return (
        <div className="vecta-section bg-gray" id='about'>
            <div className="container">
                <AboutContentWrapper>
                    <AboutContent>
                        <div className="section-title-wrapper mb-24">
                            <h1 className="section-caption">Know us more!</h1>
                            <h1 className="section-title">About Us</h1>
                        </div>
                        <p className="vecta-content">At the heart of our photography business lies our unrelenting passion for the art of capturing moments through a lens. This passion started at an early age, where we found ourselves gravitating towards cameras and capturing beautiful moments around us. This fascination only grew stronger with time, culminating in the decision to make photography our career path. With a Bachelor of Fine Arts in professional Photography under our belts, we embarked on our journey as professional photographers.</p>
                        <p className="vecta-content">As a young and dynamic duo of shutterbugs, we've completed several projects, delivering captivating visual content to our clients. Our portfolio section showcases our art, featuring an array of stunning portraits, modelling shoots, and photoshoots that capture the essence of our subjects. We believe that every photograph is an opportunity to tell a unique story, and we strive to make every moment count. We invite you to take a closer look at our portfolio section to see our work in portrait, modelling, photoshoot, and more, and experience the passion that drives us.</p>
                    </AboutContent>
                    <AboutImageSec>
                        <AboutImageContainer>
                            <img src={AboutImage} alt="Vecta Photography" />
                        </AboutImageContainer>
                    </AboutImageSec>
                </AboutContentWrapper>
            </div>
        </div>
    )
}

export default AboutUs;
