import styled from "styled-components";
import { VectaBlack, VectaHeadingColor, VectaOrange, VectaWhite } from "../../components/colors";

export const VectaFAQWrapper = styled.div`
    max-width: 75%;
    margin: 0 auto;
    @media screen and (max-width: 991px){
        max-width: 100%;
    }
`

export const VectaFAQ = styled.div`
    
`

export const FAQQuestion = styled.div`
    background-color: transparent;
    border-left: 5px solid transparent;
    border-bottom: 0.5px solid rgba(252, 76, 2, 0.25);
    border-radius: 8px;
    padding: 18px 24px;
    display: flex;
    gap: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    h3{
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        color: ${VectaHeadingColor};
    }
    .faq-icon{
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:hover{
        h3{
            color: ${VectaOrange};
        }
        .faq-icon{
            svg{
                path{
                    fill: ${VectaOrange};
                }
            }
        }
    }
    &.active{
        background-color: ${VectaWhite};
        border-bottom: 0;
        border-left: 5px solid ${VectaOrange};
        h3{
            color: ${VectaBlack};
        }
        .faq-icon{
            svg{
                transform: rotate(180deg);
                path{
                    fill: ${VectaBlack};
                }
            }
        }
        & + div{
            height: auto;
        }
    }
`

export const FAQAnswer = styled.div`
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
    p{
        padding: 16px 24px 32px;
    }
`