import React from 'react'
import { VectaHeroContentWrapper, VectaHeroContent, VectaHeroImage, VectaHeroImageSec, VectaHeroSec } from './banner.components'

import HeroImage from '../../assets/images/image-1.png';

const Banner = () => {
    return (
        <>
            <VectaHeroSec>
                <div className="container">
                    <VectaHeroContentWrapper>
                        <VectaHeroImageSec>
                            <VectaHeroImage>
                                <img src={HeroImage} alt="VectaPhotography" />
                            </VectaHeroImage>
                        </VectaHeroImageSec>
                        <VectaHeroContent>
                            <div className="section-title-wrapper mb-0">
                                <h1 className='section-caption'>Hello!</h1>
                                <h1 className="section-title">We are Vecta</h1>
                            </div>
                            {/* <p className="section-content">It is a long established fact that a reader will be a distracted by the readable content of a page when looking at its layout.</p> */}
                            <a href="#contact" className="vecta-btn outline-btn mt-40">Contact Us</a>
                        </VectaHeroContent>
                    </VectaHeroContentWrapper>
                </div>
            </VectaHeroSec>
        </>
    )
}

export default Banner
