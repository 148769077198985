import styled from "styled-components";
import { VectaGrayV1, VectaHeadingColor, VectaTextColor } from "../../components/colors";

import quotationIcon from '../../assets/icons/quote.svg'

export const TestimonialGrid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (min-width: 1367px){
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (max-width: 575px){
        grid-template-columns: repeat(1, 1fr);
    }
`

export const TestimonialHeader = styled.div`
    justify-items: start;
`

export const TestimonialCard = styled.div`
    background-color: ${VectaGrayV1};
    border-radius: 8px;
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 120px;
        height: 120px;
        background: url(${quotationIcon});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
`

export const TestimonialContent = styled.p`
    font-size: 16px;
    line-height: 28px;
`

export const TestimonialBy = styled.h4`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${VectaHeadingColor};
    margin: 24px 0 8px;
`

export const TestimonialPersonDetails = styled.span`
    font-size: 14px;
    line-height: 21px;
    color: ${VectaTextColor};
`