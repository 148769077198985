import React from 'react'
import { TestimonialBy, TestimonialCard, TestimonialContent, TestimonialGrid, TestimonialHeader, TestimonialPersonDetails } from './testimonials.components';

const testimonialData = [
    {
        content: `As a modeling agent, I have seen a lot of portfolio photos over the years. The photos that Vecta Photography took for my client's portfolio stand out as some of the best I have ever seen. I would highly recommend Vecta Photography to anyone in need of top-quality portfolio photos.`,
        userName: 'Hope Mikaelson',
        userDesignation: 'Actor'
    },
    {
        content: `Having a professional-looking portfolio is essential for a successful modelling career, and that's why I turned to Vecta Photography for help. They did an outstanding job of capturing my unique style and look, resulting in stunning photos that truly showcase my potential as a model. Working with them was an absolute pleasure, and I highly recommend their services to anyone looking for high-quality modelling portfolio photos.`,
        userName: 'Hope Mikaelson',
        userDesignation: 'Actor'
    },
    {
        content: `Our wedding day was one of the most important days of our lives, and choosing the right photographer was essential in preserving those special moments. We are delighted to have hired Vecta Photography for the photoshoot as they did an exceptional job of capturing the beauty and emotions of our big day. The final photos were stunning, and we couldn't be happier with the results. If you're looking for a talented photographer who can capture your special moments with elegance and style, we highly recommend Vecta Photography for any photoshoot.`,
        userName: 'Hope Mikaelson',
        userDesignation: 'Actor'
    },
    {
        content: `As a professional event planner, I have worked with many photographers over the years. Vecta photography stands out as one of the best I have ever worked with. They were able to capture the atmosphere and energy of the event perfectly, and the photos turned out stunning.`,
        userName: 'Hope Mikaelson',
        userDesignation: 'Actor'
    },
    {
        content: `When it comes to an acting portfolio, having high-quality portraits that capture your unique personality and style is crucial. That's why I decided to work with Vecta Photography, and I was absolutely blown away by the results. The portraits they took were stunning, and I received numerous compliments on the photos from industry professionals. I'm confident that these photos will help me stand out in a highly competitive industry, and I'm incredibly grateful to Vecta Photography for their exceptional work.`,
        userName: 'Hope Mikaelson',
        userDesignation: 'Actor'
    }
]

const Testimonials = () => {
    return (
        <>
            <div className="vecta-section bg-black">
                <div className="container">
                    <TestimonialGrid>
                        <TestimonialHeader>
                            <div className="section-title-wrapper">
                                <h1 className="section-caption">Testimonials</h1>
                                <h1 className="section-title">Why people love</h1>
                            </div>
                        </TestimonialHeader>
                        {testimonialData.map((testimonial, i) => (
                            <TestimonialCard key={i}>
                                <TestimonialContent>{testimonial.content}</TestimonialContent>
                                {/* <TestimonialBy>{testimonial.userName}</TestimonialBy>
                                <TestimonialPersonDetails>{testimonial.userDesignation}</TestimonialPersonDetails> */}
                            </TestimonialCard>
                        ))}
                    </TestimonialGrid>
                </div>
            </div>
        </>
    )
}

export default Testimonials;
