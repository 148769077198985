export const VectaOrange = '#FC4C02';
export const VectaOrangeLight = '#FDE1D5';
export const VectaBlack = '#0B0C0C'
export const VectaBlackV2 = '#131518';
export const VectaBlackV3 = '#272B2F';
export const VectaGrayV1 = '#272626';
export const VectaGrayV2 = '#191B1D';
export const VectaTextColor = '#9F9F9F';
export const VectaHeadingColor = '#CECECE';
export const VectaPlaceholderColor = '#191B1D';
export const VectaWhite = '#F3F3F3';
export const VectaBorderColor = '#C5C5C5';