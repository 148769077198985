import styled from "styled-components";
import { VectaBorderColor, VectaGrayV1, VectaOrange, VectaWhite } from "../../components/colors";

export const VectaNavigation = styled.div`
  width: 100vw;
  height: 68px;
  background-color: ${VectaGrayV1};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  .container {
    width: 100%;
    height: 100%;
  }
`;

export const VectaNavbar = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px){
        justify-content: space-between;
    }
`

export const VectaNavLogoWrapper = styled.a`
    width: 94px;
    display: flex;
`

export const VectaNavLinks = styled.div`
flex: 1;
height: 100%;
display: flex;
justify-content: flex-end;
@media screen and (max-width: 991px){
    width: 75%;
    height: 80%;
    background: ${VectaGrayV1};
    border-radius: 0 40px 40px 0;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: -100%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    &.open{
        left: 0;
    }
}
`

export const VectaNavLink = styled.a`
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: ${VectaWhite};
    line-height: 1;
    letter-spacing: 0.25px;
    position: relative;
    &:not(:last-child){
        padding-right: 32px;
        margin-right: 32px;
        &::after{
            content: '';
            width: 1px;
            height: 20%;
            background-color: ${VectaBorderColor};
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            @media screen and (max-width: 991px){
                display: none;
            }
        }
        @media screen and (max-width: 991px){
            padding: 0;
            margin: 0;
        }
    }
    &:hover{
        color: ${VectaOrange};
    }
    @media screen and (max-width: 991px){
        height: 60px;
    }
    
`

export const VectaNavHamburger = styled.span`
    width: 32px;
    height: 32px;
    background: transparent;
    border: 1px solid ${VectaOrange};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span{
        width: 20px;
        height: 1px;
        background: ${VectaOrange};
        &.middle{
            margin: 6px 0;
        }
    }
    @media screen and (min-width: 992px){
        display: none;
    }
    &.clicked{
        .top{
            transform: translateY(6px) rotateZ(45deg);
        }
        .middle{
            opacity: 0;
        }
        .bottom{
            transform: translateY(-8px) rotateZ(-45deg);
        }
    }
`