import React from 'react'

import { Helmet } from 'react-helmet'
import Banner from './banner/banner'
import Header from './header/header'
import AboutUs from './aboutUs/aboutUs'
import Footer from './footer/footer'
import Contact from './contact/contact'
import Testimonials from './testimonials/testimonials'
import Faq from './faq/faq'
import Pricing from './pricing/pricing'
import Gallery from './gallery/gallery'

import './vecta-website-design.css'

const VectaWebsiteDesign = (props: any) => {
  return (
    <>
      {/* <Helmet>
        <title>Vecta Photography</title>
      </Helmet> */}
      <>
        <Header />
        <Banner />
        <AboutUs />
        <Gallery />
        <Pricing />
        <Faq />
        <Testimonials />
        <Contact />
        <Footer />

      </>
    </>
  )
}

export default VectaWebsiteDesign
