import styled from "styled-components";
import { VectaBlack, VectaBlackV2, VectaGrayV2, VectaOrange } from "../../components/colors";

export const VectaHeroSec = styled.div`
    height: 80vh;
    background-color: ${VectaBlack};
    display: flex;
    align-items: center;
    padding: 100px 0 0;
    .container{
        height: 100%;
    }
    @media screen and (max-width: 991px){
        height: 50vh;
        padding: 60px 0;
    }
    @media screen and (max-width: 575px){
        height: auto;
    }
`

export const VectaHeroContentWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 100px;
    @media screen and (max-width: 991px){
        gap: 80px;
    }
    @media screen and (max-width: 575px){
        flex-direction: column;
        gap: 40px;
    }
`

export const VectaHeroImageSec = styled.div`
    width: 50%;
    height: 100%;
    padding: 24px 24px 0 0;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 80%;
        height: 80%;
        background-color: transparent;
        border: 4px solid ${VectaOrange};
        border-radius: 75px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @media screen and (max-width: 575px){
            border-radius: 40px;
        }
    }
    &::after{
        content: '';
        width: calc(100% + 48px + 100px);
        height: 75%;
        background-color: ${VectaGrayV2};
        border-radius: 0 40px 40px 0;
        position: absolute;
        top: 50%;
        left: calc(-100px - 24px);
        transform: translateY(-50%);
        z-index: -2;
        @media screen and (max-width: 575px){
            display: none;
        }
    }
    @media screen and (max-width: 575px){
        width: 100%;
    }
`

export const VectaHeroImage = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${VectaBlackV2};
    border-radius: 0 60px 0 250px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
    @media screen and (max-width: 991px){
        border-radius: 80px;
    }
    @media screen and (max-width: 575px){
        border-radius: 40px;
    }
`

export const VectaHeroContent = styled.div`
    flex: 1;
`