import styled from "styled-components";
import { VectaBlack, VectaGrayV1, VectaHeadingColor, VectaOrange, VectaTextColor } from "../../components/colors";

export const PricingCardsWrapper = styled.div`
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 991px){
        flex-direction: column;
        gap: 40px;
    }
    @media screen and (max-width: 1366px){
        max-width: 100%;
    }
`

export const PricingCard = styled.div`
    flex: 1;
    background-color: ${VectaGrayV1};
    border: 1px solid rgba(206, 206, 206, 0.15);
    border-radius: 8px;
    padding: 70px 60px;
    display: flex;
    flex-direction: column;
    &.recommended{
        background-color: ${VectaBlack};
        border-color: ${VectaBlack};
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media screen and (max-width: 991px){
        flex: unset;
        width: 100%;
    }
`

export const PricingCardTitleCaption = styled.span`
    display: block;
    font-weight: 400;
    color: ${VectaOrange};
    line-height: 21px;
    margin-bottom: 8px;
`

export const PricingCardTitle = styled.h2`
    font-size: 38px;
    font-weight: 700;
    color: ${VectaHeadingColor};
    line-height: 48px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${VectaOrange};
    margin-bottom: 24px;
`

export const PricingFeatures = styled.div`
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(252, 76, 2, 0.1);
    margin-bottom: 32px;
    &>span{
        font-size: 14px;
        font-weight: 400;
        color: ${VectaTextColor};
        line-height: 21px;
    }
`

export const PricingFeatureList = styled.ul`
    list-style: none;
    padding: 0 0 16px;
    margin: 0;
    li{
        &:not(:last-child){
            margin-bottom: 16px;
        }
    }
`

export const Price = styled.h4`
    font-size: 38px;
    font-weight: 600;
    color: ${VectaHeadingColor};
    margin: 32px 0 0;
`