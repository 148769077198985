import styled from "styled-components";
import { VectaBlackV3, VectaGrayV1, VectaOrange, VectaOrangeLight, VectaWhite } from "../../components/colors";

export const VectaFooter = styled.div`
    background-color: ${VectaWhite};
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`

export const FooterLogo = styled.a`
    width: 150px;
    display: flex;
    align-items: center;
    img{
        width: 100%;
    }
`

export const FooterLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 16px;
`

export const FooterLink = styled.a`
    padding: 0 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: ${VectaBlackV3};
    position: relative;
    &:not(:last-child){
        &::after{
            content: '';
            width: 1px;
            height: 80%;
            background-color: #e3e3e3;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
`

export const SocialLinks = styled.div`
    display: flex;
    gap: 16px;
`

export const SocialLink = styled.a`
    width: 32px;
    height: 32px;
    background-color: ${VectaOrangeLight};
    border: 1px solid transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        border-color: ${VectaOrange};
    }
`

export const VectaCopyrights = styled.div`
    width: 100%;
    background-color: ${VectaGrayV1};
    padding: 16px 0;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
`