import React, { useState } from 'react'
import { FAQAnswer, FAQQuestion, VectaFAQ, VectaFAQWrapper } from './faq.components';

const faqData = [
    {
        id: "1",
        question: 'Can I come to shoot without an appointment?',
        answer: 'No! All sessions are by appointment only.'
    },
    {
        id: "2",
        question: 'How do I book my portfolio shoot?',
        answer: `You need to call or whatsapp us on +91 8121916030 and book an appointment. We suggest you come for a meeting to understand the portfolio packages in detail. Once you are 100% sure, you may book your shoot with us.`
    },
    {
        id: "3",
        question: 'Do you shoot kids portfolios?',
        answer: `Yes, We shoot kid's portfolio.`
    },
    {
        id: "4",
        question: 'Do you provide makeup and hairstyling in the package?',
        answer: 'Yes, we do provide maekup and hairstylist, but if you have your own personal makeup and hair stylist, that will work too.'
    }
];

const Faq = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [currentQuest, setCurrentQuest] = useState('1');

    function openQuestion(e: any) {
        setIsClicked(true);
        setCurrentQuest(e);
    }
    return (
        <>
            <div className="vecta-section bg-gray" id='faq'>
                <div className="container">
                    <div className="section-title-wrapper text-center">
                        <h1 className="section-caption">Ask us Anything</h1>
                        <h1 className="section-title">FAQs</h1>
                    </div>
                    <VectaFAQWrapper>
                        {faqData.map((faq, i) => (
                            <VectaFAQ key={i}>
                                <FAQQuestion className={(currentQuest === faq.id ? 'active' : '')} onClick={(e) => openQuestion(faq.id)}>
                                    <h3>{faq.question}</h3>
                                    <span className="faq-icon">
                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.88002 3.42454C0.88002 2.92537 1.07207 2.44537 1.43675 2.06127C2.18555 1.31247 3.41435 1.31247 4.16315 2.06127L9.99995 7.87887L15.8367 2.04207C16.5855 1.29327 17.8143 1.29327 18.5631 2.04207C19.3119 2.79087 19.3119 4.01967 18.5631 4.76847L11.3631 11.9685C10.9983 12.3333 10.5183 12.5252 9.99988 12.5252C9.48145 12.5252 9.00145 12.314 8.63661 11.9685L1.43661 4.78767C1.07192 4.4037 0.879883 3.90454 0.879883 3.42454H0.88002Z" fill="#CECECE" stroke='none' />
                                        </svg>
                                    </span>
                                </FAQQuestion>
                                <FAQAnswer>
                                    <p>{faq.answer}</p>
                                </FAQAnswer>
                            </VectaFAQ>
                        ))}
                    </VectaFAQWrapper>
                </div>
            </div>
        </>
    )
}

export default Faq;
